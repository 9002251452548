import moment from 'moment-timezone';
import TransactionConstants from '@/mixins/TransactionConstants';
import stringUtils from '@/util/Strings';
import numberUtils from '@/util/Numbers';

// Add mixin.
const filters = {
  created: () => ({}),
  mounted: () => ({}),
  methods: {
    ...stringUtils,
    ...numberUtils,
    date(value, relative = false, toFormat = 'DD/MM/YYYY HH:mm', fromFormat = moment.ISO_8601, locale = null) {
      if (value === null || value === undefined || value === '') {
        return '-';
      }

      let momentObj = null;
      if (value instanceof Date) {
        momentObj = moment(value);
      } else if (value instanceof moment) {
        momentObj = value;
      } else {
        momentObj = moment(String(value), fromFormat);
      }

      if (relative) {
        return momentObj.utc(true).fromNow();
      }

      return momentObj.format(toFormat);
    },
    humanReadablePaymentMethod(value) {
      return TransactionConstants.paymentMethodLabel(value);
    },
    humanReadableTransactionStatus(value) {
      return TransactionConstants.transactionStatusLabel(value);
    },
  },
};

// Export.
export default filters;
